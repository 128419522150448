import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  CNPJ_MASK,
  CPF_MASK,
  PHONE_LANDLINE_MASK,
  PHONE_MASK,
  PHONE_MASK_DINAMIC,
} from 'shared/constants/masks.constants';

@Injectable()
export class BaseFormComponent {
  constructor(private snackBar: MatSnackBar) {}

  userId = Number(sessionStorage['userId']);
  userName = sessionStorage['nome'];
  isAdmin =
    sessionStorage['perfil'] === 'admin' || sessionStorage['perfil'] === 'superintcomercial';
  isLeaderManager = sessionStorage['perfil'] === 'gestorlider';
  isManager = sessionStorage['perfil'] === 'gestor';

  phone_dinamic_mask = PHONE_MASK_DINAMIC;
  phone_mask = PHONE_MASK;
  phone_landline_mask = PHONE_LANDLINE_MASK;
  cpf_mask = CPF_MASK;
  cnpj_mask = CNPJ_MASK;

  activeObject = [
    { id: 0, description: 'Todos' },
    { id: true, description: 'Ativo' },
    { id: 'false', description: 'Inativo' },
  ];

  hasError(formControl: AbstractControl, errorName: string) {
    return (formControl.dirty || formControl.touched) && formControl.hasError(errorName);
  }

  openSnackBar(message: string, action: string, panelClass = 'default-snackbar'): any {
    this.snackBar.open(message, action, { panelClass });
  }

  //for debug purpose
  getAllErrors(form: UntypedFormGroup | UntypedFormArray): { [key: string]: any } | null {
    let hasError = false;

    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      const errors =
        control instanceof UntypedFormGroup || control instanceof UntypedFormArray
          ? this.getAllErrors(control)
          : control.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as { [key: string]: any });

    return hasError ? result : null;
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  mySubscription: any;
  constructor(private router: Router) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!sessionStorage['apiToken']) {
      this.router.navigate(['/']);
      return false;
    }
    // else{
    //   var route_slug = next.params["company"];
    //   this.clientService.getSlug().subscribe(api_slug => {
    //    if(api_slug != route_slug){
    //      var check_slug = false;
    //      //token atual está configurado para outra empresa(cliente) resgatar novo token e redirecionar usuario
    //      this.authService.getUserClients().subscribe(response =>{
    //       response.forEach(item =>{
    //           if(item.slug == route_slug){
    //             this.authService.getClientToken(item.iD_Cli).subscribe(new_token =>{
    //               this.authService.setState(new_token);
    //              location.reload();
    //             });
    //             check_slug = true;
    //           }
    //       });

    //       if(!check_slug)//nao possui acesso à empresa solicitada
    //         this.router.navigate(['/']);

    //     });
    //     //this.router.navigate(['/']);
    //    }
    //   });
    // }
    return true;
  }
}

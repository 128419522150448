import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {
  goTo: number;
  pageNumbers: number[];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @Input() disabled = false;
  @Input() hidePageSize = false;
  @Input() pageSizeOptions: number[];
  @Input() showFirstLastButtons = true;
  @Output() page = new EventEmitter<PageEvent>();
  @Input() pageIndex: number;
  @Input() length: number;
  @Input() pageSize: number;

  constructor() {}

  ngOnInit() {
    this.updateGoto();
  }

  updateGoto() {
    let arredondar = Math.ceil(this.length / this.pageSize);
    this.goTo = (this.pageIndex || 0) + 1;
    this.pageNumbers = [1];
    for (let i = 2; i <= arredondar; i++) {
      this.pageNumbers.push(i);
    }
  }

  onPageChange(pageEvt: PageEvent) {
    this.length = pageEvt.length;
    this.pageIndex = pageEvt.pageIndex;
    this.pageSize = pageEvt.pageSize;
    this.updateGoto();
    this.emitPageEvent(pageEvt);
  }

  goToChange() {
    this.paginator.pageIndex = this.goTo - 1;
    this.emitPageEvent({
      length: this.paginator.length,
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
    });
  }

  emitPageEvent(pageEvent: PageEvent) {
    this.page.next(pageEvent);
  }
}

import { Injectable } from '@angular/core';
import { MenuInterface } from 'shared/interfaces/menu.interface';

@Injectable({
  providedIn: 'root',
})
export class MenuItemsService {
  public navigation: MenuInterface[] = [
    {
      title: 'Home',
      url: '/home',
      icon: 'today',
      permission: 'dashboard',
    },
    {
      title: 'Comunicados',
      url: '/comunicados',
      icon: 'mail',
      permission: 'faq',
    },
    {
      title: 'Processos',
      url: '/processos',
      icon: 'space_dashboard',
      permission: 'processos',
    },
    {
      title: 'Simulador',
      url: '/simulador',
      icon: 'calculate',
      permission: 'dashboard',
    },
  ];

  constructor() { }
}

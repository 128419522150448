import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'shared/services/auth.service';

@Component({
  selector: 'app-check-auth',
  templateUrl: './check-auth.component.html',
  styleUrls: ['./check-auth.component.scss'],
})
export class CheckAuthComponent implements OnInit {
  isLogged = AuthService.isAuthenticated();

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.usuarioLogado();
  }

  usuarioLogado() {
    if (this.isLogged) {
      console.log('To no check auth')
      return this.router.navigate(['/home']);
    } else {
      return this.router.navigate(['/login']);
    }
  }
}

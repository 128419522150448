<div class="paginator">
  <div class="go-to-container">
    <div class="go-to-label">Página:</div>
    <mat-form-field>
      <mat-select [(ngModel)]="goTo" (selectionChange)="goToChange()">
        <mat-option
          *ngFor="let pageNumber of pageNumbers"
          [value]="pageNumber"
          >{{ pageNumber }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>

  <mat-paginator
    [length]="length"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    (page)="onPageChange($event)"
    showFirstLastButtons
  >
  </mat-paginator>
</div>

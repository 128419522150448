import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CheckAuthComponent } from 'shared/components/check-auth/check-auth.component';
import { AppBlankComponent } from './core/app-blank/app-blank.component';
import { AppDefaultComponent } from './core/app-default/app-default.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: CheckAuthComponent,
  },
  {
    component: AppBlankComponent,
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    component: AppDefaultComponent,
    path: 'home',
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    component: AppDefaultComponent,
    path: 'comunicados',
    loadChildren: () =>
      import('./modules/faq/faq.module').then(
        (m) => m.FAQModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    component: AppDefaultComponent,
    path: 'sem-permissao',
    loadChildren: () =>
      import('./modules/without-permission/without-permission.module').then(
        (m) => m.WithoutPermissionModule,
      ),
    canActivate: [AuthGuard],
  },

  {
    component: AppDefaultComponent,
    path: 'processos',
    loadChildren: () =>
      import('./modules/processes/processes.module').then(
        (m) => m.ProcessesModule,
      ),
    canActivate: [AuthGuard],
  },

  {
    component: AppDefaultComponent,
    path: 'simulador',
    loadChildren: () =>
      import('./modules/simulator/simulator.module').then(
        (m) => m.SimulatorModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    component: AppDefaultComponent,
    path: 'leads',
    loadChildren: () =>
      import('./modules/leads/leads.module').then(
        (m) => m.LeadsModule,
      ),
    canActivate: [AuthGuard],
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'shared/shared.module';
import { AppBlankComponent } from './app-blank/app-blank.component';
import { AppDefaultComponent } from './app-default/app-default.component';
import { HeaderComponent } from './header/header.component';
import { ProfileModalComponent } from './header/profile-modal/profile-modal.component';
import { SidebarComponent } from './sidebar/sidebar.component';

const COMPONENTS = [
  AppBlankComponent,
  AppDefaultComponent,
  HeaderComponent,
  SidebarComponent,
  ProfileModalComponent,
];

@NgModule({
  declarations: [COMPONENTS],
  imports: [CommonModule, SharedModule],
  exports: [],
})
export class CoreModule {}

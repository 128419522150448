<div class="custom-modal">
    <div class="header">
        <h1 mat-dialog-title>
            Dados cadastrais do {{ user.nome }}
        </h1>
        <mat-icon mat-dialog-close>close</mat-icon>
    </div>
    <mat-divider></mat-divider>
    <div mat-dialog-content class="content">
        <form [formGroup]="userForm">
            <mat-form-field>
                <mat-label>Nome:</mat-label>
                <input matInput placeholder="Nome do usuário" formControlName="nome" name="nome" [value]="user.nome"
                    [(ngModel)]="user.nome">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Email:</mat-label>
                <input matInput placeholder="Email do usuário" formControlName="email" name="email" [value]="user.email"
                    [(ngModel)]="user.email">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Celular:</mat-label>
                <input matInput mask="(00) 00000-0000" placeholder="Número do celular" formControlName="telefone"
                    name="telefone" maxlength="15" [value]="user.telefone" [(ngModel)]="user.telefone">
            </mat-form-field>
            <button mat-stroked-button color="primary" (click)="alterarSenha.toggle();mostraSenhas();"
                style="margin-bottom: 1.25rem;">
                Alterar Senha
            </button>
            <mat-checkbox hidden #alterarSenha></mat-checkbox>
            <ng-container *ngIf="alterarSenha.checked">
                <div class="change-password">
                    <mat-form-field>
                        <mat-label>Senha Atual:</mat-label>
                        <input matInput placeholder="Insira a senha atual" formControlName="senhaAtual" type="password"
                            name="senhaAtual" [(ngModel)]="user.senhaAtual">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Nova Senha:</mat-label>
                        <input matInput placeholder="Insira a nova senha" formControlName="senhaNova" type="password"
                            name="senhaNova" [(ngModel)]="user.senhaNova">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Confirmar Nova Senha:</mat-label>
                        <input matInput placeholder="Confirme a nova senha" formControlName="senhaConfirmacao"
                            type="password" name="senhaConfirmacao" [(ngModel)]="user.senhaConfirmacao">
                    </mat-form-field>
                </div>
            </ng-container>
        </form>
    </div>
    <div class="modal-actions" mat-dialog-actions align="start">
        <button mat-stroked-button mat-dialog-close>Cancelar</button>
        <button mat-raised-button (click)="salvar()" color="primary">Salvar</button>
    </div>
</div>
<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<header>
  <div class="header">
    <div class="responsive-menu" style="width:15px!important">
      <a (click)="openResponsiveMenu()">
        <mat-icon>menu</mat-icon>
      </a>
    </div>
    <div class="logo-side" style="background-color: #fff;">
      <img src="../../../assets/images/LOGO.png" width="240" height="80" />
    </div>
    <div class="user-side">

      <nav mat-tab-nav-bar class="user-side-menu">
        <a mat-tab-link *ngFor="let menu of menuItems" (click)="activeLink = menu.element.url"
          [active]="activeLink == menu.element.url" routerLinkActive="selected" [routerLink]="menu.element.url">
          {{menu.labelPortal}} </a>
      </nav>
      <div>
        <!--<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Notificações">
          <mat-icon style="color:#fff">{{setNotification()}}</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <a class="notification" mat-menu-item *ngFor="let notification of listNotification" href="/comunicados">
            <span class="notification-title">
              {{notification.type}} {{notification.data}}
            </span>
            <p class="notification-text">
              {{notification.title}}
            </p>

          </a>

        </mat-menu>-->
      </div>
      <div class="user-side--content">
        <button mat-button [matMenuTriggerFor]="menuUser">
          <div class="menu-user">
            <div class="photo">
              <ngx-avatar *ngIf="!user?.photo" size="45" bgColor="white" fgColor="red" [round]="true"
                name="{{ onlyFirstName(user?.name) }}" value="75%"></ngx-avatar>
            </div>
            <mat-icon style="color: white;">expand_more</mat-icon>
          </div>
        </button>
        <mat-menu #menuUser="matMenu" xPosition="before">
          <button (click)="openDialog()" class="user-content-button" mat-menu-item>
            <mat-icon>settings</mat-icon>
            Configurações
          </button>
          <button class="user-content-button" (click)="logout()" mat-menu-item>
            <mat-icon>arrow_back</mat-icon>
            Sair
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</header>

<div class="responsive-menu-content">
  <ul class="navigation">
    <li *ngFor="let menu of menuItems; index as i">
      <ng-container *ngIf="!menu.children">
        <a [routerLink]="menu.element.url" routerLinkActive="active" style="display: flex">
          <mat-icon class="mr-2">{{ menu.element.icon }}</mat-icon>
          <span> {{ menu.labelPortal }}</span>
        </a>
      </ng-container>
      <ng-container *ngIf=" menu.element.children">
        <mat-accordion class="sidebar-accordion">
          <mat-expansion-panel>
            <mat-expansion-panel-header [collapsedHeight]="customCollapsedHeight"
              [expandedHeight]="customExpandedHeight">
              <mat-panel-title>
                <mat-icon class="mr-2">{{ menu.element.icon }}</mat-icon>
                <span>{{ menu.element.title }}</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a *ngFor="let child of  menu.element.children" class="sidebar-child" [routerLink]="child.url"
              routerLinkActive="active">
              <div class="menu-icone mr-2">
                <i [class]="child.icon"></i>
              </div>
              <span>{{ child.title }}</span>
            </a>
          </mat-expansion-panel>
        </mat-accordion>
      </ng-container>
    </li>
  </ul>
</div>
import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxMaskModule, IConfig } from 'ngx-mask';

import { AppComponent } from './app.component';
import { ApiInterceptor } from './guards/api.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from 'shared/shared.module';
import { CoreModule } from './core/core.module';

import { environment } from 'env/environment';

registerLocaleData(localePt);

const MASK_CONFIG: Partial<IConfig> = {
  validation: false,
  allowNegativeNumbers: false,
  thousandSeparator: '.',
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    CoreModule,
    NgxMaskModule.forRoot(MASK_CONFIG),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: environment.language },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'shared/services/auth.service';
import { MenuItemsService } from 'shared/services/menu.service';
import { ProfileModalComponent } from './profile-modal/profile-modal.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public customCollapsedHeight = '48px';
  public customExpandedHeight = '48px';
  comunicados: any[][];

  user: any = {};
  alerts: any;

  menuItems = [];
  clientId = null;
  cliente: any = {};
  isExpanded = true;
  collapsedClass = '';
  panelOpenState = false;
  icon = '';
  hasNotification: boolean = false;
  listNotification: any[] = [{ type: 'Comunicado', title: 'Férias Thiago', data: '08/02/2023 12:11' }]
  background: string = 'blue';
  activeLink: string;

  constructor(
    public dialog: MatDialog,
    public menu: MenuItemsService,
    public authService: AuthService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
  ) {
    this.user = {
      name: sessionStorage['nome'],
      login: sessionStorage['login'],
    };
  }

  ngOnInit() {
    this.loadPermissionsMenu();
    this.toggleExpanded();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ProfileModalComponent, {
      width: '540px',
    });
    dialogRef.afterClosed().subscribe(() => { });
  }

  onlyFirstName(name: string): string {
    return name ? name.replace(/ .*/, '') : '';
  }

  loadPermissionsMenu() {
    this.authService.getPermissionByUserId(sessionStorage['userId']).subscribe({
      next: (data: any) => {
        if (data) {
          const userPermissions = data;
          this.menu.navigation.forEach((element) => {
            var itemMenu = userPermissions.filter(function (p) {
              return p.siglaFuncionalidade == element.permission;
            });

            if (itemMenu.length > 0) this.menuItems.push({
              element,
              labelPortal: ''
            });
          });
          this.menuItems.push({
            element: {
              "title": "Leads",
              "url": "/leads",
              "icon": "today",
              "permission": "dashboard"
          },
            labelPortal: ''
          });
          this.menuItems[0].labelPortal = 'Home'
          this.menuItems[1].labelPortal = 'Comunicados'
          this.menuItems[2].labelPortal = 'Acompanhamento de Processos'
          this.menuItems[3].labelPortal = 'Simulações'
          this.menuItems[4].labelPortal = 'Acompanhamento de Leads'
          this.activeLink = this.menuItems[0];
        }
      },
    });
  }

  toggleExpanded() {
    this.isExpanded = !this.isExpanded;
    this.icon = this.isExpanded ? 'fa fa-arrow-right' : 'fa fa-arrow-left';

    if (this.isExpanded) {
      this.collapsedClass = 'collapsed';
    } else {
      this.collapsedClass = '';
    }
  }

  openResponsiveMenu(): void {
    const menu = document.querySelector('.responsive-menu-content');
    menu.classList.toggle('open');
  }

  logout(): void {
    this.authService.logout();
  }

  setNotification() {
    return this.listNotification.length > 0 ? 'notifications_active' : 'notifications_none'
  }
  toggleBackground() {
    this.background = 'blue';
  }

}

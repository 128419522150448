import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginUserInterface } from 'shared/interfaces/auth.interface';
import { API } from 'env/environment.dev';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private router: Router) {}

  login(login: string, senha: string): Observable<any> {
    return this.http.post(`${API.USER}/login/${login}/${senha}`, httpOptions);
  }

  authenticate(credentials: any): Observable<any> {
    return this.http.post(`${API.AUTH}/`, credentials, httpOptions);
  }

  setState(data: any) {
    sessionStorage['apiToken'] = data.auth_token;
    sessionStorage['userId'] = data.id;
    sessionStorage['nome'] = data.name;
    sessionStorage['perfil'] = data.perfil;
  }

  static isAuthenticated() {
    return !!sessionStorage['apiToken'];
  }

  logout() {
    sessionStorage.clear();
    this.router.navigate(['/']);
  }

  getById(id: string): Observable<any> {
    return this.http.get<any>(`${API.USER}/get/${id}`, httpOptions);
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.get<any>(`${API.AUTH}/forgotPassword/${email}`, httpOptions);
  }

  updatePassword(obj: LoginUserInterface): Observable<any> {
    return this.http.put<any>(`${API.AUTH}/updatePassword`, obj, httpOptions);
  }

  updateUser(obj: any): Observable<any> {
    return this.http.put<any>(`${API.USER}/update`, obj, httpOptions);
  }

  getUserByEmailAndPassword(email: string, password: string): Observable<any> {
    return this.http.post<any>(
      `${API.AUTH}/getUserByEmailAndPassword`,
      { username: email, password: password },
      httpOptions,
    );
  }

  verifyPassword(obj: any): Observable<any> {
    return this.http.post<any>(`${API.AUTH}/verifyPassword`, obj, httpOptions);
  }

  getPermissionByUserId(userId: any): Observable<any> {
    return this.http.get<any>(`${API.USER_PROFILE}/listarPermissoesUsuario/${userId}`, httpOptions);
  }

  checkPermission(userId, feature): Observable<any> {
    return this.http.get<any>(
      `${API.USER_PROFILE}/checarPermissao/${userId}/${feature}`,
      httpOptions,
    );
  }
}
